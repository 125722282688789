<template>
  <v-container class="invoices-create" fluid>
    <entity-modify-header class="mb-10" :title="title" />

    <form-builder v-if="!isLoading" :schema="$options.schema" :initial-data="initialData" @submit="submit">
      <template #footer="{ valid }">
        <v-row class="mt-10">
          <v-col>
            <v-btn color="secondary" block class="primary--text" :to="backRoute">{{ $t('button.back') }}</v-btn>
          </v-col>
          <v-col>
            <v-btn type="submit" color="primary" block :loading="isDataSent" :disabled="!valid">{{ buttonText }}</v-btn>
          </v-col>
        </v-row>
      </template>
    </form-builder>
  </v-container>
</template>

<script>
// Models
import { schema } from '@/schemas/InvoicesRecurrence.schema';

// Utils
import { createModelData } from '@/schemas/createModelData';

// Components
import FormBuilder from '@/components/schema/FormBuilder.vue';
import EntityModifyHeader from '@/components/EntityModifyHeader.vue';

// Services
import paymentsService from '@/services/payments';
import notificationService from '@/services/notification';
import companyService from '@/services/company';
import analyticsService from '@/services/analytics';

// Constants
import { INVOICES_RECURRENCE } from '@/constants/routes';
import { CREATE_RULE } from '@/constants/analyticsActions';

export default {
  name: 'InvoicesRecurrenceModify',

  components: { FormBuilder, EntityModifyHeader },

  props: {
    id: { type: Number, default: -1 },
    isEdit: { type: Boolean, required: true },
  },

  data() {
    return {
      initialData: createModelData(schema),
      isDataSent: false,
      isLoading: false,
    };
  },

  computed: {
    backRoute() {
      return { name: INVOICES_RECURRENCE, query: { page: +this.$route.query.prevPage || 1 } };
    },

    title() {
      if (this.isEdit) {
        return this.$t('invoice.edit_recurrence_invoice');
      }

      return this.$t('invoice.issue_recurrence_invoice');
    },

    buttonText() {
      if (this.isEdit) {
        return this.$t('button.edit_invoice');
      }

      return this.$t('button.send_recurrence_invoice');
    },
  },

  async created() {
    this.isLoading = true;

    try {
      const automaticInvoiceNumberingIsEnable = (await companyService.getSettingsFlag()).automaticInvoiceNumbering;

      this.initialData.automaticInvoiceNumbering = automaticInvoiceNumberingIsEnable;
      this.initialData.automaticInvoiceNumberingTooltip = automaticInvoiceNumberingIsEnable
        ? 'invoice.automatic_recurrence_invoice_numbering_tooltip'
        : undefined;

      if (this.isEdit) {
        const invoice = await paymentsService.getRecurrenceInvoice({ id: this.id });

        const unit = invoice.unit.parentUnit
          ? {
              id: invoice.unit.parentUnit,
              name: invoice.unit.parentUnitName,
            }
          : invoice.unit;

        const room = invoice.unit.parentUnit
          ? {
              id: invoice.unit.id,
              name: invoice.unit.name,
            }
          : null;

        this.initialData = {
          ...this.initialData,
          ...invoice,
          unit,
          room,
          services: invoice.services.map(service => ({ ...service, amount: parseFloat(service.amount) })),
        };
      }
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    submit(recurrenceData) {
      if (this.$options.notificationItem) {
        notificationService.remove(this.$options.notificationItem);
      }

      this.isDataSent = true;

      const preparedRecurrenceData = {
        ...recurrenceData,
        unit: recurrenceData.room.id || recurrenceData.unit.id,
        clientId: recurrenceData.client.id,
        recurrencePeriod: recurrenceData.recurrencePeriod.value,
        contractor: recurrenceData.contractor.id,
        serviceType: recurrenceData.serviceType.value,
      };

      const fetch = this.isEdit ? paymentsService.updateRecurrenceInvoice : paymentsService.createRecurrenceInvoice;
      analyticsService.track(CREATE_RULE);

      fetch({ ...preparedRecurrenceData, id: this.id })
        .then(() => {
          if (this.isEdit) {
            notificationService.success(this.$t('invoice.invoice_edited'), 3000);
          } else {
            notificationService.success(this.$t('invoice.invoice_created'), 3000);
          }

          this.$router.push({ name: INVOICES_RECURRENCE });
        })
        .catch(error => {
          this.$options.notificationItem = notificationService.error(
            `${this.$t('error.found_errors')} ${Object.keys(error?.response?.data).join(', ')}`
          );
        })
        .finally(() => {
          this.isDataSent = false;
        });
    },
  },

  schema,
  notificationItem: null,
};
</script>

<style lang="scss">
.invoices-create {
  max-width: 548px;
}
</style>
